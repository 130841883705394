export var MAO_Payment = (function () {
    return {
        init: function () {
            $('#payment_mao')
                .off('click')
                .on('click', function (e) {
                    e.preventDefault();

                    let btnText = $('#payment_mao').html();
                    $('#payment_mao').html('<i class="fa fa-spinner fa-spin"></i> ' + btnText);
                    $('#payment_mao').prop('disabled', true);

                    if (MAO_Payment.validatePayment()) {
                        document.location.href = route.overview;
                    } else {
                        // Reset button state
                        $('#payment_mao').html(btnText);
                        $('#payment_mao').prop('disabled', false);
                    }
                });

            $('#birthday_day,#birthday_month,#birthday_year')
                .off('change')
                .on('change', function (e) {
                    e.preventDefault();
                    MAO_Payment.storeBirthday();
                });

            $('.payment-group')
                .off('click')
                .on('click', function () {
                    MAO_Payment.hideError();

                    if (!$(this).is('.active')) {
                        $('.payment-group').removeClass('active');
                        $(this).addClass('active');

                        var _radio = $(this).find('input[type=radio]');
                        var _state = $(_radio).prop('checked');
                        $(_radio).prop('checked', !_state);

                        var group = $(this).find('.checkout-payment-block__input').val();
                        var method = $('.checkout-payment-block__input:checked').data('method-id');

                        MAO_Payment.selectMethod(method, group);
                    }
                });

            $('.mao-select.payment')
                .off('change')
                .on('change', function () {
                    MAO_Payment.refreshSelectBoxes();
                    if ($(this).val() != '') {
                        if (MAO_Payment.validatePayment() || $(this).hasClass('multi')) {
                            MAO_Payment.updatePaymentMethod();
                        }
                    }
                });
        },
        storeBirthday: function () {
            var d = $('#birthday_day').val();
            var m = $('#birthday_month').val();
            var y = $('#birthday_year').val();

            if (d && m && y) {
                var dob = y + '-' + m + '-' + d;
                $.post('/birthday', {birthday: dob}, function (h) {});
            }
        },
        validatePayment: function () {
            var isValidated = false;
            // Check if a valid paymentgroup is selected
            if ($('.checkout-payment-block__input:checked').length > 0) {
                isValidated = true;
            }

            // If applicable, check if a valid paymentmethod is selected
            if ($('.payment-group.active').find('select').length > 0) {
                if ($('.payment-group.active').find('select').val() == '') {
                    isValidated = false;
                }
            }

            // If applicable, check if a radiobuttons is checked
            if (!isValidated) {
                if ($('.payment-group.active').find('select').length > 0) {
                    if ($('.payment-group.active').find('select').length == 0) {
                        isValidated = true;
                    }
                }
            }

            // Trigger Mollie creditcard
            if (isValidated && $('.checkout-payment-block__input:checked').data('method-id') === 21) {
                isValidated = false;

                // Click button to validate credit card data
                $('.submit-button').trigger('click');
            }

            // Validate After Pay birthdate
            if (isValidated == true && $('.checkout-payment-block__input:checked').val() == '9') {
                var day = $('#birthday_day').val();
                var month = $('#birthday_month').val();
                var year = $('#birthday_year').val();

                if (day < 1 || month < 1 || year < 1) {
                    MAO_Payment.showBirthdayError();

                    return false;
                } else {
                    MAO_Payment.hideBirthdayError();

                    return true;
                }
            }

            if (!isValidated) {
                MAO_Payment.showError();
                return false;
            } else {
                MAO_Payment.hideError();
                return true;
            }
        },
        refreshSelectBoxes: function () {
            // Set all other selectboxes to empty values
            $('.payment-group').not('.active').find('.mao-select .multi').val('');
        },

        selectMethod: function (choosenMethod, choosenGroup) {
            $('.cart-summary-container')
                .css({'font-size': 'xx-large', 'text-align': 'center', 'margin-bottom': '10px'})
                .html('<i class="fa fa-spinner fa-spin"></i>');
            $.post(
                route.checkout.updatepaymentmethod,
                {paymentMethod: choosenMethod, paymentGroup: choosenGroup},
                function (data) {
                    if (data.status && data.status == 'success') {
                        if (data.message && data.message.shoppingCartSummary) {
                            MAO_Payment.setShoppingCartSummary(data.message.shoppingCartSummary);
                        }
                    }
                    MAO_Payment.init();
                },
                'json'
            );
        },

        setShoppingCartSummary: function (shoppingCartSummary) {
            $('#shoppingCartSummary').html(shoppingCartSummary);
        },

        showError: function () {
            $('.mao-notification.warn').removeClass('hide');
        },

        hideError: function () {
            $('.mao-notification.warn').addClass('hide');
        },

        showBirthdayError: function () {
            $('.mao-notification.birthday').removeClass('hide');
        },

        hideBirthdayError: function () {
            $('.mao-notification.birthday').addClass('hide');
        },

        updatePaymentMethod: function () {
            var group = $('.payment-group.active').find('.checkout-payment-block__input').val();
            var method = $('.checkout-payment-block__input:checked').data('method-id');

            MAO_Payment.selectMethod(method, group);
        },

        updateCartSummary: () => {
            $('#shoppingCartSummary').html(data.message['shoppingCartSummary']);
            MAO_Payment.init();
        },

        showConfirmation: function () {
            $('.discountSuccess').removeClass('hide');
        },

        hideConfirmation: function () {
            $('.discountSuccess').addClass('hide');
        },

        showCodeError: function () {
            $('.discountError').removeClass('hide');
            MAO_Payment.hideConfirmation();
        },

        hideCodeError: function () {
            $('.discountError').addClass('hide');
        },
    };
})();
